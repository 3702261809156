<template>
    <div class="berserk">
        <Loading :visible="loading" />
        <div class="containerMain">
            <div class="headTitle">
                <div class="htBg">
                    <img src="./images/listhot.png" alt="" width="52" height="62">
                    <div class="headerText">
                        <p>疯抢榜 <span>TOP100</span></p>
                        <p>跟着大众， 学会如何精致生活</p>
                    </div>
                    <div class="time">
                        距离下次排名更新还有<span class="min">{{ minutes }}</span>分<span class="sec">{{ seconds }}</span>秒
                    </div>
                </div>
            </div>
            <!-- tabs -->
            <div class="tabs" bi-route="qiehuan">
                <div bi-click="tags" data-name="实时销量榜" :class="['tabsItem', tabsIndex === 1 ? 'active' : '']"
                    @click="handleChageTabs(1)">
                    <i class="img"></i>
                    <div>
                        <div class="tit">实时销量榜</div>
                        <div class="des">近两小时热销商品</div>
                    </div>
                </div>
                <div bi-click="tags" data-name="全天销量榜" :class="['tabsItem', tabsIndex === 2 ? 'active' : '']"
                    @click="handleChageTabs(2)">
                    <i class="img day"></i>
                    <div>
                        <div class="tit">全天销量榜</div>
                        <div class="des">今天哪些商品卖得最多</div>
                    </div>
                </div>
            </div>
            <!-- warp menu -->
            <div v-if="isShowFoldTips && cid == 0 && tips" class="goodsHidden">{{ tips }}<button class="btn"
                    @click="handleListHIdden">{{ listHidden ? "点击显示" : "点击隐藏" }}</button>
            </div>
            <div class="catWarp" bi-route="fenlei">
                <div class="catList">
                    <ul>
                        <li bi-click="navs" :data-name="item.cname" :data-cid="item.cid"
                            :data-tags="tabsIndex == 1 ? '实时销量榜' : '全天销量榜'" v-for="(item, index) in menuList" :key="index"
                            :class="menuIndex === index ? 'active' : ''" @click="hanldeChangeMenu(index, item)">
                            <span>{{ item.cname }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- goodList start -->
            <div class="goodList" bi-route="goods">
                <ul>
                    <li bi-click="list" :data-index="index" :data-cid="cid || null"
                        :data-tags="tabsIndex == 1 ? '实时销量榜' : '全天销量榜'" :data-gid="item.id || item.goodsId"
                        v-for="(item, index) in goodsList" :key="item.id" :class="index < 3 ? 'top' : ''">
                        <div class="rank">{{ index > 2 ? index + 1 : '' }}</div>
                        <div class="goodsItem" @click="handleItemClick(item)">
                            <div class="img" v-marketgroup="item"><img v-lazy="`${item.mainPic}_310x310.jpg`" alt=""></div>
                            <div class="detail">
                                <a :title="item.dtitle || item.title"
                                    :href="`?r=/l/d&amp;nav_wrap=p&amp;id=${item.id || item.goodsId}`" target="_blank"
                                    @click.stop="">
                                    <p class="tit">{{ item.dtitle || item.title }}</p>
                                </a>
                                <div>
                                    <span class="num">近{{ tabsIndex === 1 ? '2' : '24' }}小时热度值 <span>{{ tabsIndex === 1 ?
                                        item.twoHoursSales : item.dailySales | salesNum }}</span></span>
                                    <div class="lineBlock" :title="'优惠券已领取' + item.couponReceiveNum + '张'">
                                        <div class="line">
                                            <em
                                                :style="{ width: computeNummber(item.couponReceiveNum, item.couponTotalNum) }"></em>
                                        </div>
                                    </div>
                                    <div class="wrap">
                                        <div class="price">
                                            <b>券后</b>￥<span>{{ item.actualPrice |
                                                salesNum }}</span><i><b>￥</b>{{ item.originalPrice | salesNum }}</i>
                                        </div>
                                        <div class="icon">
                                            <i v-if="item.activityType === 3" class="ju tagTit" title="此商品正在参加聚划算"></i>
                                            <i v-if="item.tchaoshi === 1 || item.shopType === 1"
                                                :class="[item.tchaoshi ? 'maoc' : 'tmail', 'tagTit']"
                                                :title="item.tchaoshi ? '天猫超市' : '天猫'"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <MicroDetail @onClose="handleMicroDetailClose" v-if="visible" :visible="visible" :id="microDetailId" />
            <!-- goodList end -->

        </div>

    </div>
</template>

<script>
import { getRankingList, getClassify, getRankFoldRule } from './services/index'
import MicroDetail from "@/components/MicroDetail";
import Loading from "@/components/loading";
import { mapGetters } from "vuex";
var timer = ''
export default {
    computed: {
        ...mapGetters(["config"])
    },
    components: {
        MicroDetail,
        Loading
    },
    async mounted() {
        const value = window.localStorage.getItem("list_hidden");
        if (!value) {
            this.listHidden = true;
            window.localStorage.setItem("list_hidden", this.listHidden ? 1 : 0);
        } else {
            this.listHidden = value == 1;
        }

        if (this.$route.query.tabsIndex) {
            this.tabsIndex = Number(this.$route.query.tabsIndex)
        }
        if (this.config.site_name) {
            document.title = `疯抢榜_${this.config.site_name}`;
        }
        timer = setInterval(() => {
            var time = new Date()
            this.hours = time.getHours() % 2
            this.minutes = (this.hours == 0 ? 1 : 0) * 60 + (60 - time.getMinutes())
            this.seconds = 60 - time.getSeconds()
        }, 1000)

        this.getRankFoldRuleData()

        this.getClassifyList()
    },
    destroyed() {
        timer = null
    },
    data() {
        return {
            tabsIndex: 1,
            loading: true,
            menuIndex: 0,
            hours: 0,   //时
            minutes: 0, //分
            seconds: 0, //秒
            rankType: '',
            cid: '', //分类
            menuList: [],
            goodsList: [],
            visible: false,
            isShowFoldTips: false,
            tips: '',
            listHidden: true,
            microDetailId: "",
        }
    },
    methods: {
        handleChageTabs: function (index) {
            this.tabsIndex = index
            this.getRankList(index)
        },
        hanldeChangeMenu: function (index, item) {
            this.menuIndex = index
            this.cid = item.cid
            var params = {
                rankType: this.rankType,
                cid: item.cid
            }
            this.findGoodList(params)
        },
        computeNummber: function (pre, next = 0) {
            if (pre === 0 && next === 0 || next === 0) {
                return '0%'
            } else {
                let num = (Number(pre) / Number(next)) * 100
                return (num <= 100 ? num : 100) + '%'
            }
        },
        getRankList: function (rankType = 1) {
            const _this = this
            _this.rankType = rankType

            let params = {
                rankType,
                cid: this.cid
            }
            _this.findGoodList(params)
        },
        findGoodList: function (params) {
            if (this.isShowFoldTips && this.cid == 0) {
                // 官网逻辑
                // ...(!cids || cids =='-1'? {
                //                 list_hidden:!this.state.isShowFoldTips?0:(localStorage.getItem('list_hidden')?localStorage.getItem('list_hidden'):1),
                //             } : {}),
                params['listHidden'] = this.listHidden ? 1 : 0
            }

            this.loading = true
            const _this = this
            getRankingList(params).then(res => {
                this.loading = false
                _this.goodsList = res.data
            })
        },
        getClassifyList: function () {
            const _this = this
            getClassify().then(res => {
                _this.menuList = [{ cid: '', cname: '全部' }, ...res.data]
            })
        },

        getRankFoldRuleData: function () {
            const _this = this
            getRankFoldRule().then(res => {
                if (res.data.tip_cms_pc) {
                    _this.tips = res.data.tip_cms_pc;
                }
                _this.isShowFoldTips = res.data.switch == '1' ? true : false;
                _this.getRankList(_this.tabsIndex)
            })
        },
        //点击商品
        handleItemClick(item) {
            if (!this.config.micro_switch) {
                window.open(
                    `?r=l/d&u=1&id=${item.id || item.goodsId}&from=dtk`,
                    "_blank"
                );
            } else {
                this.visible = true
                this.microDetailId = item.id || item.goodsId
            }
        },
        handleMicroDetailClose() {
            this.visible = false
            this.microDetailId = null
        },
        handleListHIdden() {
            const value = window.localStorage.getItem("list_hidden") == 1;
            this.listHidden = !value
            window.localStorage.setItem("list_hidden", this.listHidden ? 1 : 0);
            this.getRankList(this.tabsIndex)
        },
    }
}
</script>

<style lang="less" scoped>
.berserk {
    background: #f6f6f6;
    min-width: 1200px;

    .containerMain {
        width: 1200px;
        margin: 0 auto;
        min-height: 500px;
        padding-left: 8px;
        position: relative;

        .headTitle {
            padding: 33px 0 0 78px;
            position: relative;

            .htBg {
                width: 970px;
                height: 86px;
                background: url('./images/listtopbg.png') center no-repeat;
                background-size: 100% 100%;
                padding: 10px 0 0 370px;
                box-sizing: border-box;

                img {
                    float: left;
                    margin-right: 12px;
                }

                .headerText {
                    float: left;

                    p:first-child {
                        font-size: 29px;
                        font-weight: 700;
                        color: rgba(254, 46, 84, 1);
                        line-height: 36px;
                    }

                    p:last-child {
                        font-size: 18px;
                        color: rgba(51, 51, 51, 1);
                        line-height: 24px;
                    }
                }

                .time {
                    color: #757575;
                    position: absolute;
                    right: 10px;
                    top: 76px;
                    font-size: 13px;

                    .hour {
                        font-size: 17px;
                        font-family: Arial;
                        color: rgba(254, 46, 84, 1);
                        display: inline-block;
                        width: 20px;
                        margin: 0 2px;
                        text-align: center;
                        padding: 0 2px;
                    }

                    .min {
                        font-size: 17px;
                        font-family: Arial;
                        color: rgba(254, 46, 84, 1);
                        display: inline-block;
                        margin: 0 2px;
                        text-align: center;
                        padding: 0 2px;
                    }

                    .sec {
                        font-size: 17px;
                        font-family: Arial;
                        color: rgba(254, 46, 84, 1);
                        display: inline-block;
                        margin: 0 2px;
                        text-align: center;
                        padding: 0 2px;
                    }
                }
            }
        }

        .tabs {
            margin: 30px auto 27px;
            line-height: 42px;
            display: flex;
            justify-content: center;

            .tabsItem {
                width: 240px;
                height: 69px;
                background: #fff;
                display: flex;
                align-items: center;
                border-right: 1px solid rgba(236, 236, 236, 1);
                border-bottom: 1px solid rgba(236, 236, 236, 1);
                border-top: 1px solid rgba(236, 236, 236, 1);
                cursor: pointer;

                .img {
                    width: 27px;
                    height: 27px;
                    display: inline-block;
                    background: url('./images/listhour.png') no-repeat;
                    background-size: contain;
                    position: relative;
                    margin-left: 22px;
                    margin-right: 21px;
                }

                .day {
                    background: url('./images/listday.png');
                    background-size: contain;
                }

                .tit {
                    color: #FE2E54;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFangSC;
                    font-weight: 400;
                    line-height: 25px;
                    margin-bottom: 6px;
                }

                .des {
                    font-size: 13px;
                    font-family: MicrosoftYaHei;
                    color: rgba(153, 153, 153, 1);
                    line-height: 17px;
                }
            }

            .active {
                background: #FE2E54;
                position: relative;

                .tit,
                .des {
                    color: #fff;
                }

                .img {
                    background: url('./images/listhouract.png');
                    background-size: contain;
                }

                .day {
                    background: url('./images/listdayact.png');
                    background-size: contain;
                }

                &::after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 7.5px solid transparent;
                    border-right: 7.5px solid transparent;
                    border-top: 9px solid #FE2E54;
                    bottom: -6.5px;
                    left: 28px;
                    position: absolute;
                }
            }
        }

        .catWarp {
            position: relative;
            width: 1198px;
            border: 1px solid #eee;
            border-bottom: none;
            margin-bottom: 15px;

            .catList {
                background: #f9f9f9;
                display: block;
                border-bottom: #eee 1px solid;

                ul {
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    padding: 0 20px;
                    line-height: 50px;
                    border-radius: 6px;
                    background: #fff;
                    list-style: none;

                    li {
                        margin-right: 15px;
                        cursor: pointer;

                        span {
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            padding: 1px 8px;
                            text-decoration: none;
                        }
                    }

                    .active {
                        span {
                            background: rgba(254, 46, 84, 1);
                            border-radius: 11px;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .goodsHidden {
            height: 40px;
            background: #FFFBF8;
            border: 1px solid #FFDDC5;
            border-radius: 5px;
            display: flex;
            margin-bottom: 15px;
            align-items: center;
            justify-content: center;
            color: #F2704A;
            font-size: 14px;

            .btn {
                margin-left: 10px;
                width: 86px;
                height: 26px;
                background: #FF5827;
                border-radius: 5px;
                font-size: 13px;
                color: #FFFFFF;
                border-width: 0;
                cursor: pointer;
            }
        }

        .clearfix {
            content: ".";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
        }

        .goodList {
            width: 1217px;
            margin-top: 36px;
            padding-bottom: 50px;

            ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 224px;
                    height: 375px;
                    background: rgba(255, 255, 255, 1);
                    border-radius: 6px;
                    border: none;
                    margin-bottom: 17px;
                    position: relative;
                    display: block;
                    float: left;
                    margin-right: 17px;

                    .rank {
                        position: absolute;
                        width: 33px;
                        height: 24px;
                        line-height: 20px;
                        background: url('./images/listrank.png') center no-repeat;
                        top: 10px;
                        left: -8px;
                        text-align: center;
                        color: #fff;
                        z-index: 1;
                    }

                    &:nth-child(1) {
                        .rank {
                            position: absolute;
                            right: 60px;
                            left: auto;
                            top: -22px;
                            width: 40px;
                            height: 76px;
                            background: url('./images/listrank1.png') center no-repeat;
                            background-size: contain;
                        }

                        .num {}

                        .tit {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    &:nth-child(2) {
                        .rank {
                            position: absolute;
                            right: 60px;
                            left: auto;
                            top: -22px;
                            width: 40px;
                            height: 76px;
                            background: url('./images/listrank2.png') center no-repeat;
                            background-size: contain;
                        }

                        .goodsItem .detail .num {
                            background: rgba(237, 241, 255, 1);
                            color: #992EFE;
                        }

                        .tit {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    &:nth-child(3) {
                        .rank {
                            position: absolute;
                            right: 60px;
                            left: auto;
                            top: -22px;
                            width: 40px;
                            height: 76px;
                            background: url('./images/listrank3.png') center no-repeat;
                            background-size: contain;
                        }

                        .tit {
                            white-space: normal;
                            text-align: left;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .goodsItem .detail .num {
                            background: #FFF5ED;
                            color: #FF7C1A;
                        }
                    }

                    .goodsItem {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: -1px;
                        top: -1px;
                        border: 1px solid transparent;
                        border-radius: 6px;
                        cursor: pointer;

                        .img {
                            width: 224px;
                            height: 224px;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                display: inline;
                                position: relative;
                            }
                        }

                        .detail {
                            padding: 0 10px;
                            box-sizing: border-box;
                            text-align: center;

                            a {
                                text-decoration: none;
                            }

                            .tit {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                text-align: center;
                                font-size: 13px;
                                color: #494949;
                                margin-top: 15px;
                            }

                            .lineBlock {
                                width: 180px;
                                margin: 0 auto 5px;
                                height: 20px;
                                position: relative;
                                padding: 9px 0;
                                box-sizing: border-box;

                                .line {
                                    width: 180px;
                                    border-top: 2px solid #EBEBEB;

                                    em {
                                        position: absolute;
                                        left: 0;
                                        top: 9px;
                                        border-top: 2px solid #FC4D52;
                                    }
                                }
                            }

                            .num {
                                display: inline-block;
                                background: rgba(255, 237, 237, 1);
                                border-radius: 2px;
                                color: #FE2E54;
                                text-align: center;
                                line-height: 20px;
                                margin: 15px auto 10px;
                                // padding: 0 15px;
                                box-sizing: border-box;
                                font-size: 13px;
                                height: 20px;
                                min-width: 152px;
                            }

                            .price {
                                color: #FC4D52;
                                font-size: 12px;
                                text-align: left;
                                margin-left: 1px;
                                float: left;

                                b {
                                    font-style: normal;
                                    font-weight: 400;
                                }

                                span {
                                    font-size: 21px;
                                    font-family: Arial;
                                    font-weight: 700;
                                    line-height: 28px;
                                }

                                i {
                                    color: #929292;
                                    border-top: 1px solid #929292;
                                    line-height: 0;
                                    display: inline-block;
                                    font-style: normal;
                                }
                            }

                            .icon {
                                // width: 65px;
                                height: 16px;
                                margin: 8px -5px 0 0;
                                overflow: hidden;
                                float: right;

                                i {
                                    float: right;
                                    width: 16px;
                                    height: 16px;
                                    background-size: 100% 100%;
                                    margin-right: 3px;
                                    position: relative;

                                    &.tmail {
                                        background: url('./images/tmall.png') center no-repeat;
                                        background-size: contain;
                                    }

                                    &.maoc {
                                        background: url('./images/maoc.png') center no-repeat;
                                        background-size: contain;
                                    }

                                    &.ju {
                                        background: url('./images/ju.png') center no-repeat;
                                        background-size: contain;
                                    }
                                }
                            }

                            &:hover {
                                a p {
                                    color: #FC4D52;
                                }
                            }
                        }

                        &:hover {
                            .img img {
                                transform: scale(1.05, 1.05);
                                transition-duration: 0.5s;
                            }

                        }
                    }
                }

                .top {
                    width: 390px;
                    height: 224px;
                    margin-bottom: 36px;
                    margin-right: 11px;
                    overflow: visible;

                    .rank {
                        position: absolute;
                        right: 60px;
                        left: auto;
                        top: -22px;
                        width: 40px;
                        height: 76px;
                        background-size: contain;
                    }

                    .img {
                        position: absolute;
                        height: 100%;
                        width: 225px;
                        border-radius: 6px 0 0;
                    }

                    .detail {
                        float: right;
                        width: 165px;
                        overflow: hidden;
                        margin-top: 60px;
                        padding: 0 10px;
                        box-sizing: border-box;
                        text-align: center;

                        .tit {
                            white-space: normal !important;
                            text-align: left;
                        }

                        .lineBlock {
                            display: none;
                        }

                        .num {
                            padding: 0 3px;
                            height: 28px;
                            line-height: 28px;
                            box-sizing: border-box;
                            white-space: nowrap;
                            width: 100%;

                            span {
                                font-size: 16px;
                                font-family: Arial;
                                font-weight: 700;
                            }
                        }

                        .price {
                            color: #FC4D52;
                            font-size: 12px;
                            margin-left: 1px;
                            text-align: left;

                            b {
                                display: none;
                            }

                            span {
                                font-size: 21px;
                                font-family: Arial;
                                font-weight: 700;
                                line-height: 28px;
                                color: #FC4D52;
                                margin-left: 1px;
                                line-height: 0;
                                display: inline-block;
                            }

                            i {
                                border: none;
                                color: #929292;
                                margin-left: 5px;
                                font-style: normal;

                                &::before {
                                    content: '天猫价 ';
                                }
                            }
                        }

                        .icon {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
</style>
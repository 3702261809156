import request from "@/utils/request";
// 实时销量榜
export async function getRankingList(params) {
    return request("/api/goods/get-ranking-list", {
        method: "GET",
        // requestType: "form",
        data: { ...params, ...{ version: "v1.3.0", pageSize: 100 } },
        isOpenApi: true,
    });
}

// 超级分类
export async function getClassify(params) {
    return request("/api/category/get-super-category", {
        method: "GET",
        // requestType: "form",
        data: { ...params, ...{ version: "v1.1.0" } },
        isOpenApi: true,
    });
}

// 榜单折叠规则获取
// https://yapi.buydance.com/project/86/interface/api/10365
export async function getRankFoldRule(params) {
      return request('/dtk_go_app_api/v1/get-rank-fold-rule',{
      method:"GET",
      data: params,
    });
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "berserk" },
    [
      _c("Loading", { attrs: { visible: _vm.loading } }),
      _c(
        "div",
        { staticClass: "containerMain" },
        [
          _c("div", { staticClass: "headTitle" }, [
            _c("div", { staticClass: "htBg" }, [
              _c("img", {
                attrs: {
                  src: require("./images/listhot.png"),
                  alt: "",
                  width: "52",
                  height: "62"
                }
              }),
              _vm._m(0),
              _c("div", { staticClass: "time" }, [
                _vm._v(" 距离下次排名更新还有"),
                _c("span", { staticClass: "min" }, [
                  _vm._v(_vm._s(_vm.minutes))
                ]),
                _vm._v("分"),
                _c("span", { staticClass: "sec" }, [
                  _vm._v(_vm._s(_vm.seconds))
                ]),
                _vm._v("秒 ")
              ])
            ])
          ]),
          _c("div", { staticClass: "tabs", attrs: { "bi-route": "qiehuan" } }, [
            _c(
              "div",
              {
                class: ["tabsItem", _vm.tabsIndex === 1 ? "active" : ""],
                attrs: { "bi-click": "tags", "data-name": "实时销量榜" },
                on: {
                  click: function($event) {
                    return _vm.handleChageTabs(1)
                  }
                }
              },
              [_c("i", { staticClass: "img" }), _vm._m(1)]
            ),
            _c(
              "div",
              {
                class: ["tabsItem", _vm.tabsIndex === 2 ? "active" : ""],
                attrs: { "bi-click": "tags", "data-name": "全天销量榜" },
                on: {
                  click: function($event) {
                    return _vm.handleChageTabs(2)
                  }
                }
              },
              [_c("i", { staticClass: "img day" }), _vm._m(2)]
            )
          ]),
          _vm.isShowFoldTips && _vm.cid == 0 && _vm.tips
            ? _c("div", { staticClass: "goodsHidden" }, [
                _vm._v(_vm._s(_vm.tips)),
                _c(
                  "button",
                  { staticClass: "btn", on: { click: _vm.handleListHIdden } },
                  [_vm._v(_vm._s(_vm.listHidden ? "点击显示" : "点击隐藏"))]
                )
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "catWarp", attrs: { "bi-route": "fenlei" } },
            [
              _c("div", { staticClass: "catList" }, [
                _c(
                  "ul",
                  _vm._l(_vm.menuList, function(item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        class: _vm.menuIndex === index ? "active" : "",
                        attrs: {
                          "bi-click": "navs",
                          "data-name": item.cname,
                          "data-cid": item.cid,
                          "data-tags":
                            _vm.tabsIndex == 1 ? "实时销量榜" : "全天销量榜"
                        },
                        on: {
                          click: function($event) {
                            return _vm.hanldeChangeMenu(index, item)
                          }
                        }
                      },
                      [_c("span", [_vm._v(_vm._s(item.cname))])]
                    )
                  }),
                  0
                )
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "goodList", attrs: { "bi-route": "goods" } },
            [
              _c(
                "ul",
                _vm._l(_vm.goodsList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: item.id,
                      class: index < 3 ? "top" : "",
                      attrs: {
                        "bi-click": "list",
                        "data-index": index,
                        "data-cid": _vm.cid || null,
                        "data-tags":
                          _vm.tabsIndex == 1 ? "实时销量榜" : "全天销量榜",
                        "data-gid": item.id || item.goodsId
                      }
                    },
                    [
                      _c("div", { staticClass: "rank" }, [
                        _vm._v(_vm._s(index > 2 ? index + 1 : ""))
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "goodsItem",
                          on: {
                            click: function($event) {
                              return _vm.handleItemClick(item)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "marketgroup",
                                  rawName: "v-marketgroup",
                                  value: item,
                                  expression: "item"
                                }
                              ],
                              staticClass: "img"
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: item.mainPic + "_310x310.jpg",
                                    expression: "`${item.mainPic}_310x310.jpg`"
                                  }
                                ],
                                attrs: { alt: "" }
                              })
                            ]
                          ),
                          _c("div", { staticClass: "detail" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  title: item.dtitle || item.title,
                                  href:
                                    "?r=/l/d&nav_wrap=p&id=" +
                                    (item.id || item.goodsId),
                                  target: "_blank"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                }
                              },
                              [
                                _c("p", { staticClass: "tit" }, [
                                  _vm._v(_vm._s(item.dtitle || item.title))
                                ])
                              ]
                            ),
                            _c("div", [
                              _c("span", { staticClass: "num" }, [
                                _vm._v(
                                  "近" +
                                    _vm._s(_vm.tabsIndex === 1 ? "2" : "24") +
                                    "小时热度值 "
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("salesNum")(
                                        _vm.tabsIndex === 1
                                          ? item.twoHoursSales
                                          : item.dailySales
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "lineBlock",
                                  attrs: {
                                    title:
                                      "优惠券已领取" +
                                      item.couponReceiveNum +
                                      "张"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "line" }, [
                                    _c("em", {
                                      style: {
                                        width: _vm.computeNummber(
                                          item.couponReceiveNum,
                                          item.couponTotalNum
                                        )
                                      }
                                    })
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "wrap" }, [
                                _c("div", { staticClass: "price" }, [
                                  _c("b", [_vm._v("券后")]),
                                  _vm._v("￥"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("salesNum")(item.actualPrice)
                                      )
                                    )
                                  ]),
                                  _c("i", [
                                    _c("b", [_vm._v("￥")]),
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("salesNum")(item.originalPrice)
                                      )
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "icon" }, [
                                  item.activityType === 3
                                    ? _c("i", {
                                        staticClass: "ju tagTit",
                                        attrs: { title: "此商品正在参加聚划算" }
                                      })
                                    : _vm._e(),
                                  item.tchaoshi === 1 || item.shopType === 1
                                    ? _c("i", {
                                        class: [
                                          item.tchaoshi ? "maoc" : "tmail",
                                          "tagTit"
                                        ],
                                        attrs: {
                                          title: item.tchaoshi
                                            ? "天猫超市"
                                            : "天猫"
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ])
                            ])
                          ])
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm.visible
            ? _c("MicroDetail", {
                attrs: { visible: _vm.visible, id: _vm.microDetailId },
                on: { onClose: _vm.handleMicroDetailClose }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerText" }, [
      _c("p", [_vm._v("疯抢榜 "), _c("span", [_vm._v("TOP100")])]),
      _c("p", [_vm._v("跟着大众， 学会如何精致生活")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "tit" }, [_vm._v("实时销量榜")]),
      _c("div", { staticClass: "des" }, [_vm._v("近两小时热销商品")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "tit" }, [_vm._v("全天销量榜")]),
      _c("div", { staticClass: "des" }, [_vm._v("今天哪些商品卖得最多")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }